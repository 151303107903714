export const fundNameToSlug = (name: string) => {
   const names: any = {
      'AUAG PRECIOUS GREEN A': 'preciousGreenA',
      'AUAG PRECIOUS GREEN B': 'preciousGreenB',
      'AUAG SILVER BULLET A': 'silverBulletA',
      'AUAG SILVER BULLET B': 'silverBulletB',
      'AUAG ESSENTIAL METALS A': 'essentialMetalsA',
      'AUAG ESSENTIAL METALS B': 'essentialMetalsB',
      'AUAG GOLD MINING ETF': 'goldMining',
      'AUAG GOLD MINING ETF GBP': 'goldMining',
      'AUAG GOLD MINING ETF EURO': 'goldMining',
      'AUAG GOLD MINING ETF CHF': 'goldMining',
      'AUAG GOLD MINING A': 'goldMiningDailyA',
      'AUAG GOLD MINING B': 'goldMiningDailyB',
      'AUAG GOLD RUSH A': 'goldMiningDailyA',
      'AUAG GOLD RUSH B': 'goldMiningDailyB',
      'AUAG GOLD RUSH H': 'goldRushNok',
   }

   return names[name]
}

export const fundSlugToName = (name: string) => {
   const names: any = {
      preciousGreenA: 'AUAG PRECIOUS GREEN A',
      preciousGreenB: 'AUAG PRECIOUS GREEN B',
      silverBulletA: 'AUAG SILVER BULLET A',
      silverBulletB: 'AUAG SILVER BULLET B',
      essentialMetalsA: 'AUAG ESSENTIAL METALS A',
      essentialMetalsB: 'AUAG ESSENTIAL METALS B',
      goldMining: 'AUAG GOLD MINING ETF',
      goldMiningDailyA: 'AUAG GOLD MINING A',
      goldMiningDailyB: 'AUAG GOLD MINING B',
   }

   return names[name]
}

export const fundToCleanName = (name: string) => {
   const names: any = {
      'AUAG PRECIOUS GREEN A': 'AuAg Precious Green A',
      'AUAG PRECIOUS GREEN B': 'AuAg Precious Green B',
      'AUAG SILVER BULLET A': 'AuAg Silver Bullet A',
      'AUAG SILVER BULLET B': 'AuAg Silver Bullet B',
      'AuAg Gold Mining - ETF (USD)': 'AuAg Gold Mining - ETF (USD)',
      'AuAg Gold Mining - ETF (GBP)': 'AuAg Gold Mining - ETF (GBP)',
      'AuAg Gold Mining - ETF (CHF)': 'AuAg Gold Mining - ETF (CHF)',
      'AuAg Gold Mining - ETF (EUR)': 'AuAg Gold Mining - ETF (EUR)',
      'AUAG GOLD MINING A': 'AuAg Gold Rush A',
      'AUAG GOLD MINING B': 'AuAg Gold Rush B',
      'AUAG GOLD RUSH A': 'AuAg Gold Rush A',
      'AUAG GOLD RUSH B': 'AuAg Gold Rush B',
      'AUAG ESSENTIAL METALS A': 'AuAg Essential Metals A',
      'AUAG ESSENTIAL METALS B': 'AuAg Essential Metals B',
   }

   return names[name]
}

export const fundToHref = (name: string) => {
   const names: any = {
      'AuAg Precious Green A': '/investment-solutions/auag-precious-green',
      'AuAg Precious Green B': '/investment-solutions/auag-precious-green',
      'AuAg Silver Bullet A': '/investment-solutions/auag-silver-bullet',
      'AuAg Silver Bullet B': '/investment-solutions/auag-silver-bullet',
      'AuAg Gold Mining - ETF (USD)': '/investment-solutions/auag-gold-mining',
      'AuAg Gold Mining - ETF (GBP)': '/investment-solutions/auag-gold-mining',
      'AuAg Gold Mining - ETF (CHF)': '/investment-solutions/auag-gold-mining',
      'AuAg Gold Mining - ETF (EUR)': '/investment-solutions/auag-gold-mining',
      'AuAg Gold Rush A': '/investment-solutions/auag-gold-rush',
      'AuAg Gold Rush B': '/investment-solutions/auag-gold-rush',
      'AuAg Essential Metals A': '/investment-solutions/auag-essential-metals',
      'AuAg Essential Metals B': '/investment-solutions/auag-essential-metals',
   }

   return names[name]
}
