import React, { useState } from 'react'
import { PodcastListProps } from './types'
import styles from './PodcastList.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Image from '@components/UI/Image/Image'
import GraphicWave from '@components/UI/GraphicWave/GraphicWave'
import classnames from 'classnames'
import CtaButton from '@components/CtaButton/CtaButton'
import useTranslation from 'next-translate/useTranslation'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
const PodcastList: React.FC<PodcastListProps> = ({ blok }) => {
   const { items } = blok

   // const { image, title, text, links } = blok
   const { t } = useTranslation('common')

   return (
      <Box as="section" width={1} className={styles.wrapper}>
         <Container paddingY={[10]}>
            <Row flexDirection="column" alignItems="center">
               <Column width={[12 / 12, null, null, 8 / 12]}>
                  {items &&
                     items.length > 0 &&
                     items.map((item: any, index: number) => {
                        const { links, image, title, text } = item
                        return (
                           <Flex
                              key={index}
                              className={styles.item}
                              flexDirection={['column', null, null, 'row']}
                           >
                              {image && image.filename && (
                                 <Box className={styles.image}>
                                    <Image
                                       src={image.filename}
                                       alt={image.alt}
                                    />
                                 </Box>
                              )}

                              <Flex
                                 flexDirection="column"
                                 className={styles.content}
                              >
                                 {title && (
                                    <Box
                                       className={styles.title}
                                       marginBottom={[2]}
                                    >
                                       {title}
                                    </Box>
                                 )}
                                 {text && (
                                    <Box
                                       className={styles.text}
                                       marginBottom={[4]}
                                    >
                                       {text}
                                    </Box>
                                 )}

                                 <Flex
                                    marginTop="auto"
                                    alignItems="center"
                                    className={styles.linkTitle}
                                 >
                                    {links &&
                                       links.length > 0 &&
                                       links.map((link: any, index: number) => (
                                          <LinkComponent
                                             key={index}
                                             className={styles.link}
                                             href={link.link.cached_url}
                                          >
                                             {link.title}
                                          </LinkComponent>
                                       ))}
                                 </Flex>
                              </Flex>
                           </Flex>
                        )
                     })}
               </Column>
            </Row>
         </Container>
      </Box>
   )
}

export default PodcastList
