import moment from 'moment'
import 'moment/locale/sv'

const goldClones = [
   'AUAG GOLD MINING ETF EURO',
   'AUAG GOLD MINING ETF GBP',
   'AUAG GOLD MINING ETF CHF',
]

export const getNavString = (nav: any, locale?: string, t?: any) => {
   const percentage =
      nav && nav.percentage ? parseFloat(nav.percentage).toFixed(2) : null
   const price = nav && nav.price ? parseFloat(nav.price).toFixed(2) : ''
   if (nav && nav.name === 'AUAG GOLD MINING ETF') {
      if (locale === 'sv') {
         return `NAV ${nav.svDate} | ETF: ${price.replace('.', ',')} ${
            nav.currency
         }${
            percentage
               ? ` | Förändring ${
                    percentage.includes('-') ? '' : '+'
                 }${percentage.replace('.', ',')}%`
               : ''
         }`
      }
      if (locale === 'no') {
         return `NAV ${nav.svDate} | ETF: ${price.replace('.', ',')} ${
            nav.currency
         }${
            percentage
               ? ` | Endring ${
                    percentage.includes('-') ? '' : '+'
                 }${percentage.replace('.', ',')}%`
               : ''
         }`
      }
      if (locale === 'en') {
         return `NAV ${nav.date} | ETF: ${price} ${nav.currency}${
            percentage
               ? ` | Change ${
                    percentage.includes('-') ? '' : '+'
                 }${percentage}%`
               : ''
         }`
      }
   }

   if (locale === 'sv' && t) {
      return `NAV ${nav.svDate} | ${t('shareClass')} ${
         nav.shareClass
      } ${price.replace('.', ',')} (${nav.currency})${
         percentage
            ? ` | Förändring ${
                 percentage.includes('-') ? '' : '+'
              }${percentage.replace('.', ',')}%`
            : ''
      }`
   }
   if (locale === 'no' && t) {
      return `NAV ${nav.svDate} | ${t('shareClass')} ${
         nav.shareClass
      } ${price.replace('.', ',')} (${nav.currency})${
         percentage
            ? ` | Endring ${
                 percentage.includes('-') ? '' : '+'
              }${percentage.replace('.', ',')}%`
            : ''
      }`
   }
   if (locale === 'en') {
      return `NAV ${nav.date} | Share ${
         nav.shareClass ? `Class ${nav.shareClass} ` : ''
      }${price} (${nav.currency})${
         percentage
            ? ` | Change ${percentage.includes('-') ? '' : '+'}${percentage}%`
            : ''
      }`
   }

   return ''
}

export const getLatestSharedData = (funds: any) => {
   const sortedData = funds.sort(function (a: any, b: any) {
      const bDate: any = new Date(b.date)
      const aDate: any = new Date(a.date)
      return aDate - bDate
   })
   const currentData =
      sortedData && sortedData.length > 0
         ? sortedData[sortedData.length - 1]
         : {}

   const prevData =
      sortedData && sortedData.length > 1
         ? sortedData[sortedData.length - 2]
         : null

   let shareClass = ''
   let date = ''
   let svDate = ''

   if (!currentData.percentage && prevData && prevData.price) {
      const percentage =
         parseFloat(currentData.price) / parseFloat(prevData.price)

      currentData.percentage = `${(percentage * 100 - 100).toFixed(2)}`
      if (currentData.euro && prevData.euro) {
         const euroPercentage =
            parseFloat(currentData.euro) / parseFloat(prevData.euro)
         currentData.euroPercentage = `${(euroPercentage * 100 - 100).toFixed(
            2,
         )}`
         currentData.euro = parseFloat(currentData.euro).toFixed(2)
      }
      if (currentData.gbp && prevData.gbp) {
         const gbpPercentage =
            parseFloat(currentData.gbp) / parseFloat(prevData.gbp)
         currentData.gbpPercentage = `${(gbpPercentage * 100 - 100).toFixed(2)}`
         currentData.gbp = parseFloat(currentData.gbp).toFixed(2)
      }
      if (currentData.chf && prevData.chf) {
         const chfPercentage =
            parseFloat(currentData.chf) / parseFloat(prevData.chf)
         currentData.chfPercentage = `${(chfPercentage * 100 - 100).toFixed(2)}`
         currentData.chf = parseFloat(currentData.chf).toFixed(2)
      }
   }

   if (currentData) {
      if (currentData && currentData.name && currentData.name.endsWith('A')) {
         shareClass = 'A'
      } else {
         if (
            currentData &&
            currentData.name &&
            currentData.name.endsWith('ETF')
         ) {
            shareClass = ''
         } else {
            shareClass = 'B'
         }
      }
   }

   if (currentData && prevData) {
      const currClone = { ...currentData }
      date = moment(currClone.date).locale('en').format('MMM Do YYYY')
      svDate = moment(currClone.date).locale('sv').format('Do MMMM YYYY')
   }
   return {
      ...currentData,
      date,
      svDate,
      shareClass,
   }
}

export const getLatestData = (
   name: string,
   funds: any,
   dailyA?: any,
   dailyB?: any,
) => {
   let currentDailyA = null
   let currentDailyB = null
   let latestDate: any = null
   if (dailyA) {
      const sortedDailyA = dailyA.sort(function (a: any, b: any) {
         const bDate: any = new Date(b.date)
         const aDate: any = new Date(a.date)
         return aDate - bDate
      })
      currentDailyA =
         sortedDailyA && sortedDailyA.length > 0
            ? sortedDailyA[sortedDailyA.length - 1]
            : {}
      latestDate = new Date(currentDailyA.date)
   }
   if (dailyB) {
      const sortedDailyB = dailyB.sort(function (a: any, b: any) {
         const bDate: any = new Date(b.date)
         const aDate: any = new Date(a.date)
         return aDate - bDate
      })
      currentDailyB =
         sortedDailyB && sortedDailyB.length > 0
            ? sortedDailyB[sortedDailyB.length - 1]
            : {}
   }

   const sortedData = funds
      .filter((item: any) => {
         if (latestDate) {
            if (new Date(item.date) > latestDate) {
               return false
            }
         }
         return true
      })
      .sort(function (a: any, b: any) {
         const bDate: any = new Date(b.date)
         const aDate: any = new Date(a.date)
         return aDate - bDate
      })
   const currentData =
      sortedData && sortedData.length > 0
         ? sortedData[sortedData.length - 1]
         : {}

   const prevData =
      sortedData && sortedData.length > 1
         ? sortedData[sortedData.length - 2]
         : null

   let shareClass = ''
   let date = ''
   let svDate = ''
   let tableDate = ''

   if (!currentData.percentage && prevData && prevData.price) {
      const percentage =
         parseFloat(currentData.price) / parseFloat(prevData.price)

      currentData.percentage = `${(percentage * 100 - 100).toFixed(2)}`
      if (currentData.euro && prevData.euro) {
         const euroPercentage =
            parseFloat(currentData.euro) / parseFloat(prevData.euro)
         currentData.euroPercentage = `${(euroPercentage * 100 - 100).toFixed(
            2,
         )}`
         currentData.euro = parseFloat(currentData.euro).toFixed(2)
      }
      if (currentData.gbp && prevData.gbp) {
         const gbpPercentage =
            parseFloat(currentData.gbp) / parseFloat(prevData.gbp)
         currentData.gbpPercentage = `${(gbpPercentage * 100 - 100).toFixed(2)}`
         currentData.gbp = parseFloat(currentData.gbp).toFixed(2)
      }
      if (currentData.chf && prevData.chf) {
         const chfPercentage =
            parseFloat(currentData.chf) / parseFloat(prevData.chf)
         currentData.chfPercentage = `${(chfPercentage * 100 - 100).toFixed(2)}`
         currentData.chf = parseFloat(currentData.chf).toFixed(2)
      }
   }

   if (currentData) {
      if (currentData && currentData.name && currentData.name.endsWith('A')) {
         shareClass = 'A'
      } else {
         if (
            currentData &&
            currentData.name &&
            currentData.name.endsWith('ETF')
         ) {
            shareClass = ''
         } else {
            shareClass = 'B'
         }
      }
   }

   if (currentData && prevData) {
      const currClone = { ...currentData }
      date = moment(currClone.date).locale('en').format('MMM Do YYYY')
      svDate = moment(currClone.date).locale('sv').format('Do MMMM YYYY')
      tableDate = moment(currClone.date).locale('sv').format('YY-MM-DD')
   }
   return {
      ...currentData,
      date,
      svDate,
      tableDate,
      shareClass,
      currentDailyA,
      currentDailyB,
   }
}
