import React from 'react'
import { Flex, Box } from '../Grid/Grid'
import styles from './InputCheckboxGroup.module.scss'
import classnames from 'classnames'

const InputCheckboxGroup = ({
   name,
   options,
   register,
   error,
   required = false,
   useValue = false,
   checked,
}: {
   name: string
   options: { label: string; value: string }[] | []
   register: any
   error: any
   required?: boolean
   useValue?: boolean
   checked?: boolean
}) => {
   return (
      <Box
         className={classnames(
            styles.wrapper,
            error?.message ? styles.error : '',
         )}
      >
         {error?.message && (
            <Box className={styles.error} marginTop={[2]}>
               Please fill out this field
            </Box>
         )}
         {options &&
            options?.length > 0 &&
            options.map((option: any, index: number) => (
               <Flex
                  key={`${option?.label}-${index}`}
                  marginBottom={[5, null, null, 5]}
                  className={styles.checkbox}
                  alignItems="flex-start"
               >
                  <input
                     {...register(name)}
                     type="checkbox"
                     name={name}
                     required={required}
                     id={option?.label}
                     defaultChecked={checked}
                     value={useValue ? option?.value : ''}
                  />
                  <Box as="label" marginTop={['2px']} marginLeft={[2]}>
                     {`${option?.value}${required ? '*' : ''}`}
                  </Box>
               </Flex>
            ))}
      </Box>
   )
}

export default InputCheckboxGroup
