import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { Box, Flex, Row, Column } from '@components/UI/Grid/Grid'

import React, { useState } from 'react'
import styles from './Funds.module.scss'
import { FundsProps } from './types'
import classnames from 'classnames'
import InvestorsTable from './InvestorsTable/InvestorsTable'
import Image from '@components/UI/Image/Image'
import { GetDataSourceEntries } from '@lib/datasources'
import { useRouter } from 'next/router'
import { getCountryTranslation } from '@lib/translations'
import NextImage from 'next/image'
import useTranslation from 'next-translate/useTranslation'

const Platform = ({ item }: { item: any }) => {
   const {
      logotype,
      link,
      // depot,
      // isk,
      // endowment_insurance,
      // pension_insurance,
      // note,
      // name,
   } = item

   // const [isVisible, setIsVisible] = useState(false)
   return (
      <Flex
         className={styles.platform}
         flexDirection="column"
         height="100%"
         justifyContent="center"
         alignItems="center"
         width={1}
         paddingBottom={5}
      >
         {logotype && logotype.filename && (
            <Flex
               className={styles.logotypeWrapper}
               width={1}
               alignItems="center"
               justifyContent="center"
               marginBottom={5}
            >
               <Flex
                  className={styles.logotypeLayout}
                  alignItems="center"
                  justifyContent="center"
               >
                  <Image
                     className={styles.logotype}
                     alt={logotype.alt}
                     src={logotype.filename}
                  />
               </Flex>
            </Flex>
         )}

         <Box marginTop={'auto'} width={1}>
            <Box paddingX={[5, null, null, null, 5]}>
               {link &&
                  link?.length > 0 &&
                  link.map((blok: any) => {
                     return <DynamicComponent blok={blok} key={blok._uid} />
                  })}
            </Box>
         </Box>
      </Flex>
   )
}

const Funds = ({
   funds,
   background,
   start_fund,
   start_investor = 'individual_investor',
   language_as_default_country,
}: FundsProps) => {
   // Fund filter
   const { locale } = useRouter()
   const { t } = useTranslation('common')

   const fundFilters: any[] = []
   const dataSourceFunds: any[] = GetDataSourceEntries('funds-simple')
   const dataSourceCountries: any[] = GetDataSourceEntries('countries')

   if (dataSourceFunds?.length > 0) {
      dataSourceFunds.forEach((dataSource: any) => {
         if (
            dataSource.name &&
            dataSource.name !== 'AuAg Gold Mining - ETF' &&
            !fundFilters.find((fund: any) => fund.name === dataSource.name)
         ) {
            fundFilters.push({
               name: dataSource.name,
               value: dataSource.value,
            })
         }
      })
   }
   const [activeFund, setActiveFund] = useState(
      start_fund
         ? start_fund
         : fundFilters?.length > 0
         ? fundFilters[0].value
         : '',
   )

   // Investor filter
   const [activeInvestor, setActiveInvestor] = useState(start_investor)
   const investor =
      activeInvestor === 'individual_investor'
         ? funds?.retailer_investors
         : activeInvestor === 'instuitional_investor'
         ? funds?.institutional_investors
         : funds?.financial_investors

   let platforms =
      investor && investor?.platforms && investor?.platforms?.length > 0
         ? [...investor.platforms]
         : []
   let tableData =
      investor && investor?.table_data && investor?.table_data?.length > 0
         ? [...investor.table_data]
         : []

   platforms = platforms.filter((platform: any) =>
      platform?.fund?.length > 0 ? platform.fund.includes(activeFund) : false,
   )
   // Country filter
   const countries: any[] = [
      {
         name: t('chooseCountry'),
         value: 'none',
      },
   ]

   if (platforms?.length > 0) {
      platforms.forEach((platform: any) => {
         if (
            platform?.country &&
            Array.isArray(platform?.country) &&
            !countries.find((country: any) =>
               platform.country.includes(country.value),
            )
         ) {
            platform.country.forEach((country: any) => {
               const findCountry =
                  dataSourceCountries?.length > 0 &&
                  dataSourceCountries.find(
                     (dCountry: any) => dCountry.value === country,
                  )
                     ? dataSourceCountries.find(
                          (dCountry: any) => dCountry.value === country,
                       )
                     : { name: '', value: '' }

               countries.push({
                  name: findCountry?.name
                     ? getCountryTranslation(findCountry.name, locale)
                     : '',
                  value: country,
               })
            })
         }
      })
   }
   let startCountry = null
   if (
      locale === 'no' &&
      language_as_default_country &&
      language_as_default_country.includes('no')
   ) {
      startCountry = countries.find(
         (country: any) => country.value === 'norway',
      )
   }
   if (
      locale === 'sv' &&
      language_as_default_country &&
      language_as_default_country.includes('sv')
   ) {
      startCountry = countries.find(
         (country: any) => country.value === 'sweden',
      )
   }
   const [activeCountry, setActiveCountry] = useState(
      startCountry ? startCountry.value : 'none',
   )

   platforms = platforms
      .filter((platform: any) =>
         activeCountry === 'all'
            ? true
            : platform.country.includes(activeCountry),
      )
      .sort((a: any, b: any) => {
         if (a.name < b.name) {
            return -1
         }
         if (a.name > b.name) {
            return 1
         }
         return 0
      })

   tableData = tableData
      .sort((a: any, b: any) => {
         if (a.exchange < b.exchange) {
            return -1
         }
         if (a.exchange > b.exchange) {
            return 1
         }
         return 0
      })
      .filter((data: any) =>
         data?.fund?.length > 0 ? data.fund.includes(activeFund) : false,
      )

   return (
      <Flex
         paddingTop={[5, null, null, null, 0]}
         paddingBottom={[10, null, null, 20]}
         width={1}
         flexDirection="column"
      >
         <Flex
            height={['auto', null, null, '80px']}
            className={styles.filterWrapper}
            alignItems="center"
            width={1}
            flexDirection={['column', null, null, 'row']}
         >
            <Flex
               width={[1, null, null, 4 / 12]}
               alignItems="center"
               className={styles.filter}
            >
               <Flex alignItems="center" className={styles.selectWrapper}>
                  <Flex alignItems={'center'} className={styles.icon}>
                     <NextImage
                        src="/icons/analytics.svg"
                        width="23"
                        height="23"
                        alt="Analytics icon"
                     />
                  </Flex>
                  <select
                     onChange={(e) => setActiveFund(e?.target?.value)}
                     name="fund"
                     id="fund"
                     value={activeFund}
                  >
                     {fundFilters.map((filter: any) => (
                        <option key={filter.value} value={filter.value}>
                           {filter.name}
                        </option>
                     ))}
                  </select>
                  <div className={styles.arrow}>
                     <NextImage
                        src="/icons/chevron-left-grey.svg"
                        width="10.8"
                        height="17.6"
                        alt="Chevron icon"
                     />
                  </div>
               </Flex>
            </Flex>

            <Flex
               width={[1, null, null, 4 / 12]}
               alignItems="center"
               className={styles.filter}
            >
               <Flex alignItems="center" className={styles.selectWrapper}>
                  <Flex alignItems={'center'} className={styles.icon}>
                     <NextImage
                        src="/icons/dollar.svg"
                        width="23"
                        height="23"
                        alt="Dolar icon"
                     />
                  </Flex>
                  <select
                     onChange={(e) => setActiveInvestor(e?.target?.value)}
                     name="type"
                     id="type"
                     value={activeInvestor}
                  >
                     <option value="individual_investor">
                        {t('individualInvestor')}
                     </option>
                     <option value="financial_advisor">
                        {t('financialAdvisor')}
                     </option>
                     <option value="instuitional_investor">
                        {t('institutionalInvestor')}
                     </option>
                  </select>
                  <div className={styles.arrow}>
                     <NextImage
                        src="/icons/chevron-left-grey.svg"
                        width="10.8"
                        height="17.6"
                        alt="Chevron icon"
                     />
                  </div>
               </Flex>
            </Flex>
            <Flex
               width={[1, null, null, 4 / 12]}
               alignItems="center"
               className={styles.filter}
            >
               <Flex
                  alignItems="center"
                  className={classnames(
                     styles.selectWrapper,
                     countries?.length === 1 ? styles.disabled : '',
                  )}
               >
                  <Flex alignItems={'center'} className={styles.icon}>
                     <NextImage
                        src="/icons/location.svg"
                        width="16"
                        height="24"
                        alt="Location icon"
                     />
                  </Flex>
                  <select
                     onChange={(e) => setActiveCountry(e?.target?.value)}
                     name="country"
                     id="country"
                     value={
                        countries?.length === 1
                           ? countries[0].value
                           : activeCountry
                     }
                     disabled={countries?.length === 1}
                  >
                     {countries.map((country: any) => (
                        <option key={country.value} value={country.value}>
                           {country.name}
                        </option>
                     ))}
                  </select>
                  <div className={styles.arrow}>
                     <NextImage
                        src="/icons/chevron-left-grey.svg"
                        width="10.8"
                        height="17.6"
                        alt="Chevron icon"
                     />
                  </div>
               </Flex>
            </Flex>
         </Flex>
         {platforms?.length > 0 && (
            <React.Fragment>
               <Box
                  className={styles.items}
                  paddingX={[0, null, null, 0]}
                  marginTop={[0, null, null, null, 15]}
               >
                  <Box
                     paddingX={
                        background === '#fff' ? 0 : [0, null, 0, null, null]
                     }
                     paddingTop={[10, null, null, 0]}
                     paddingBottom={[0, null, null, 5]}
                  >
                     <Row
                        flexDirection={[
                           'column',
                           null,
                           'row',
                           null,
                           null,
                           null,
                           'row',
                        ]}
                        alignItems="flex-start"
                     >
                        {platforms.map((platform: any) => (
                           <Column
                              key={platform._uid}
                              width={[1, null, 6 / 12, null, 3 / 12, 3 / 12]}
                              marginBottom={[5, null, null, 5]}
                           >
                              <Flex
                                 width={1}
                                 justifyContent={[
                                    'flex-start',
                                    null,
                                    'center',
                                    null,
                                    null,
                                    'center',
                                 ]}
                                 height="100%"
                                 className={styles.card}
                              >
                                 <Platform item={platform} />
                              </Flex>
                           </Column>
                        ))}
                     </Row>
                  </Box>
               </Box>
            </React.Fragment>
         )}
         {tableData?.length > 0 && (
            <React.Fragment>
               <Box
                  marginTop={background !== '#fff' ? [10, null, null, 15] : 0}
                  marginX={['-20px', null, null, 0]}
               >
                  <InvestorsTable
                     background={background}
                     tableData={tableData}
                  />
               </Box>
            </React.Fragment>
         )}
      </Flex>
   )
}

export default Funds
