import React from 'react'
import { BannerProps } from './types'
import styles from './Banner.module.scss'
import { CtaButtonStoryblok } from 'types/storyblok-components'
import { Flex, Box, Container, Row, Column } from '@components/UI/Grid/Grid'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import { getThemeColor } from '@lib/helpers'

const Banner: React.FC<BannerProps> = ({ blok }) => {
   const { title, text, button, size = 'small', background = 'grey' } = blok
   return (
      <Box
         as="section"
         className={styles.wrapper}
         paddingY={[10, null, null, 20]}
         marginY={[5, null, null, 10]}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
      >
         <Container>
            <Row justifyContent="center">
               <Column
                  width={[1, null, null, size === 'large' ? 12 / 12 : 10 / 12]}
               >
                  <Box className={styles.banner} paddingY={[8, null, null, 15]}>
                     <Row justifyContent="center">
                        <Column width={[1]} paddingX={[0, null, 20]}>
                           <Flex
                              alignItems={[
                                 'flex-start',
                                 null,
                                 null,
                                 'flex-end',
                              ]}
                              justifyContent="space-between"
                              flexDirection={['column', null, null, 'row']}
                              paddingX={[6, null, null, 0]}
                           >
                              <Box paddingRight={[0, null, null, 10]}>
                                 {title && (
                                    <Box
                                       as="h2"
                                       className={styles.title}
                                       marginBottom={
                                          text ? [5, null, null, 6] : 0
                                       }
                                    >
                                       {title}
                                    </Box>
                                 )}
                                 {text && (
                                    <Box as="p" className={styles.text}>
                                       {text}
                                    </Box>
                                 )}
                              </Box>
                              <Flex
                                 flexShrink={0}
                                 justifyContent={[
                                    'flex-start',
                                    null,
                                    null,
                                    'flex-end',
                                 ]}
                                 marginTop={[5, null, null, 0]}
                              >
                                 {button && button?.length > 0
                                    ? button.map((blok: CtaButtonStoryblok) => {
                                         return (
                                            <Box
                                               key={blok._uid}
                                               marginLeft={[
                                                  0,
                                                  null,
                                                  null,
                                                  'auto',
                                               ]}
                                            >
                                               <DynamicComponent blok={blok} />
                                            </Box>
                                         )
                                      })
                                    : null}
                              </Flex>
                           </Flex>
                        </Column>
                     </Row>
                  </Box>
               </Column>
            </Row>
         </Container>
      </Box>
   )
}

export default Banner
