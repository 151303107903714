import React from 'react'
import { PostGridProps } from './types'
import styles from './PostGrid.module.scss'
import { Box } from '@components/UI/Grid/Grid'

import PostGridCard from './PostGridCard/PostGridCard'

const PostGrid: React.FC<PostGridProps> = ({
   posts,
   type = 'grid',
   preloadFirst,
   background,
}) => {
   return (
      <div className={styles.wrapper}>
         <Box
            className={
               type === 'list'
                  ? styles.list
                  : [styles.grid, posts.length < 4 ? styles.simple : ''].join(
                       ' ',
                    )
            }
         >
            {posts?.map((article: any, index: number) =>
               article ? (
                  <PostGridCard
                     background={background}
                     key={article.id}
                     preload={preloadFirst && index === 0}
                     item={article}
                  />
               ) : null,
            )}
         </Box>
      </div>
   )
}

export default PostGrid
