import React, { useState } from 'react'
import { ContentProps } from './types'
import styles from './CurrentNav.module.scss'
import { Container, Box, Row, Column, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'
import useTranslation from 'next-translate/useTranslation'
import { fundToHref } from '@lib/fundNameToSlug'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'

const CurrentNav: React.FC<ContentProps> = ({
   blok,
   currentNavData: navData,
}) => {
   const { title, text, label, Background, disclaimer_text } = blok
   const { t } = useTranslation('common')

   const [activeSort, setActiveSort] = useState('')
   const [invert, setInvert] = useState(false)

   const handleClick = (sort: string) => {
      if (activeSort === sort) {
         setInvert(!invert)
      } else {
         setActiveSort(sort)
         setInvert(false)
      }
   }
   const numberWithValues = ['price', 'percentage']
   const sortedItems = activeSort
      ? navData.sort(function (a: any, b: any) {
           if (numberWithValues.includes(activeSort)) {
              const bValue: any = parseFloat(b[activeSort] ? b[activeSort] : 0)

              const aValue: any = parseFloat(a[activeSort] ? a[activeSort] : 0)
              return invert ? aValue - bValue : bValue - aValue
           } else {
              const aValue: any = b[activeSort].toLowerCase()
              const bValue: any = a[activeSort].toLowerCase()
              const aName: any = b['name'].toLowerCase()
              const bName: any = a['name'].toLowerCase()

              if (aValue === bValue) {
                 return aName > bName ? -1 : 1
              }
              if (aValue > bValue) {
                 return invert ? 1 : -1
              }
              if (bValue > aValue) {
                 return invert ? -1 : 1
              }
           }
        })
      : navData

   return (
      <Box
         as="section"
         width={1}
         className={[
            styles.wrapper,
            Background === 'purple' ? styles.purple : '',
            getThemeColor(Background).background === 'transparent'
               ? styles.transparent
               : '',
         ].join(' ')}
         bg={getThemeColor(Background).background}
         color={getThemeColor(Background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Box paddingY={[10, null, null, 20]}>
            <Container>
               <Flex
                  width={[1, null, null, 6 / 12]}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
               >
                  {label && (
                     <Box marginBottom={[4, null, null, 6]}>
                        {label && <Label title={label} />}
                     </Box>
                  )}
                  {title && (
                     <Box
                        className={styles.title}
                        as="h3"
                        marginBottom={[5, null, null, 10]}
                     >
                        {title}
                     </Box>
                  )}
                  {text && (
                     <Box
                        marginBottom={[5, null, null, 10]}
                        width={1}
                        className={styles.intro}
                     >
                        <Markdown>{text}</Markdown>
                     </Box>
                  )}
               </Flex>
               <Row justifyContent="center">
                  <Column width={[1, null, null, 1]}>
                     <div
                        className={[
                           styles.container,
                           Background === 'grey' ? styles.grey : '',
                           Background === 'purple' ? styles.purple : '',
                        ].join(' ')}
                     >
                        <table>
                           <thead>
                              <tr>
                                 <th
                                    className={
                                       activeSort === 'name'
                                          ? styles.active
                                          : ''
                                    }
                                 >
                                    <button
                                       onClick={() => {
                                          handleClick('name')
                                       }}
                                    >
                                       {t('fund')}
                                    </button>
                                 </th>
                                 <th
                                    className={
                                       activeSort === 'percentage'
                                          ? styles.active
                                          : ''
                                    }
                                 >
                                    <button
                                       onClick={() => {
                                          handleClick('percentage')
                                       }}
                                    >
                                       {t('change')}
                                    </button>
                                 </th>
                                 <th
                                    className={
                                       activeSort === 'price'
                                          ? styles.active
                                          : ''
                                    }
                                 >
                                    <button
                                       onClick={() => {
                                          handleClick('price')
                                       }}
                                    >
                                       NAV
                                    </button>
                                 </th>
                                 <th
                                    className={
                                       activeSort === 'currency'
                                          ? styles.active
                                          : ''
                                    }
                                 >
                                    <button
                                       onClick={() => {
                                          handleClick('currency')
                                       }}
                                    >
                                       {t('currency')}
                                    </button>
                                 </th>
                                 <th
                                    className={
                                       activeSort === 'date'
                                          ? styles.active
                                          : ''
                                    }
                                 >
                                    <button
                                       onClick={() => {
                                          handleClick('date')
                                       }}
                                    >
                                       {t('date')}
                                    </button>
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              {sortedItems &&
                                 sortedItems.map((item: any) => {
                                    const percentage: any = parseFloat(
                                       item.percentage,
                                    ).toFixed(2)
                                    const link = fundToHref(item.name)
                                    return (
                                       <tr key={item._uid}>
                                          <td className={styles.gotLink}>
                                             <LinkComponent href={link}>
                                                {item.name}
                                             </LinkComponent>
                                          </td>
                                          <td
                                             className={[
                                                percentage === 0
                                                   ? ''
                                                   : percentage > 0
                                                   ? styles.positive
                                                   : styles.negative,
                                             ].join(' ')}
                                          >
                                             {percentage
                                                ? `${
                                                     percentage.includes('-')
                                                        ? ''
                                                        : '+'
                                                  }${percentage.replace(
                                                     '.',
                                                     ',',
                                                  )}%`
                                                : ''}
                                          </td>
                                          <td>
                                             {parseFloat(item.price).toFixed(2)}
                                          </td>
                                          <td>{item.currency}</td>
                                          <td>{item.tableDate}</td>
                                       </tr>
                                    )
                                 })}
                           </tbody>
                        </table>
                     </div>
                  </Column>
               </Row>
               {disclaimer_text && (
                  <Row marginTop={[5, null, null, 10]}>
                     <Column width={[1, null, null, 1 / 2]}>
                        <Box
                           marginBottom={[5, null, null, 10]}
                           width={1}
                           className={styles.disclaimer}
                        >
                           <Markdown>{disclaimer_text}</Markdown>
                        </Box>
                     </Column>
                  </Row>
               )}
            </Container>
         </Box>
      </Box>
   )
}

export default CurrentNav
