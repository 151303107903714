import React from 'react'
import { SummaryProps } from './types'
import styles from './Summary.module.scss'
import { Flex, Container, Box, Row, Column } from '@components/UI/Grid/Grid'
import Label from '@components/UI/Label/Label'
import Markdown from '@components/UI/Markdown/Markdown'
import { getThemeColor, getTitleMarkup, stringify } from '@lib/helpers'
import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
import Image from '@components/UI/Image/Image'
import GraphicWave from '@components/UI/GraphicWave/GraphicWave'
import classnames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

const Summary: React.FC<SummaryProps> = ({ blok }) => {
   const {
      label,
      title,
      excerpt = '',
      list,
      media,
      text,
      cta,
      background,
      show_graphic_element,
      isLast,
      media_style = 'normal',
      media_description,
      title_markup,
   } = blok
   const { t } = useTranslation('common')

   return (
      <Box
         as="section"
         width={1}
         bg={getThemeColor(background).background}
         color={getThemeColor(background).color}
         className={styles.wrapper}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Container>
            <Box className={styles.content} paddingY={[10, null, null, 20]}>
               <Row>
                  <Column width={[1]}>
                     {label && (
                        <Box
                           display="inline-block"
                           marginBottom={[4, null, null, 6]}
                        >
                           {label && <Label title={label} />}
                        </Box>
                     )}
                     {title && (
                        <Box
                           className={styles.title}
                           as={getTitleMarkup(title_markup)}
                           marginBottom={[5, null, null, 6]}
                        >
                           {title}
                        </Box>
                     )}
                  </Column>
               </Row>
               <Row>
                  <Column width={[1, null, null, 6 / 12]}>
                     <Flex alignItems="flex-start" flexDirection="column">
                        {excerpt && (
                           <Box
                              className={styles.excerpt}
                              paddingRight={[0, null, 8]}
                              marginBottom={[8, null, null, null, 10, 20]}
                           >
                              <Markdown>{excerpt}</Markdown>
                           </Box>
                        )}
                        <Box width={1}>
                           <Row flexDirection="column">
                              {list &&
                                 list.length > 0 &&
                                 list.map((block: any) => {
                                    const { image, text, _uid } = block || {}

                                    return (
                                       <Column
                                          key={_uid}
                                          width={[1, null, null, 5 / 6]}
                                          paddingY={[5, null, null, 10]}
                                          className={styles.listItem}
                                       >
                                          <Flex
                                             alignItems="center"
                                             width={1}
                                             className={styles.listContent}
                                          >
                                             {image && (
                                                <Image
                                                   src={image?.filename}
                                                   alt={image?.alt}
                                                   sizes={{
                                                      mobile: '50px',
                                                      tablet: '50px',
                                                      desktop: '50px',
                                                   }}
                                                />
                                             )}
                                             {text && (
                                                <Box marginLeft={[5]} as="span">
                                                   {text}
                                                </Box>
                                             )}
                                          </Flex>
                                       </Column>
                                    )
                                 })}
                           </Row>
                        </Box>
                     </Flex>
                  </Column>

                  <Column
                     marginTop={['12px', null, null, 0]}
                     width={[1, null, null, 6 / 12]}
                  >
                     <Flex flexDirection="column" alignItems="flex-start">
                        {media && media?.length > 0 && (
                           <Flex
                              marginBottom={
                                 (cta && cta.length > 0) || text
                                    ? [5, null, null, 15]
                                    : 0
                              }
                              flexDirection="column"
                              width={1}
                           >
                              <Box
                                 className={classnames(
                                    styles.media,
                                    media_style === 'rounded'
                                       ? styles.rounded
                                       : '',
                                 )}
                              >
                                 {media.map((blok: any) => (
                                    <DynamicComponent
                                       key={blok._uid}
                                       blok={blok}
                                    />
                                 ))}
                              </Box>
                              {media_description && (
                                 <Box
                                    marginTop={[2]}
                                    paddingX={[6]}
                                    className={styles.mediaDescription}
                                    as="p"
                                 >
                                    {media_description}
                                 </Box>
                              )}
                           </Flex>
                        )}
                        <div>
                           {text && (
                              <Box
                                 marginBottom={
                                    cta && cta.length > 0
                                       ? [5, null, null, 15]
                                       : 0
                                 }
                              >
                                 <Markdown className={styles.text}>
                                    {text}
                                 </Markdown>
                              </Box>
                           )}
                           {cta &&
                              cta.length > 0 &&
                              cta.map((blok: any) => {
                                 return (
                                    <Box key={blok._uid}>
                                       <DynamicComponent blok={blok} />
                                    </Box>
                                 )
                              })}
                        </div>
                     </Flex>
                  </Column>
               </Row>
            </Box>
         </Container>
         {show_graphic_element && (
            <GraphicWave
               className={
                  isLast
                     ? [styles.graphic, styles.last].join(' ')
                     : styles.graphic
               }
            />
         )}
      </Box>
   )
}

export default Summary
